import { createApp, ref } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from 'pinia'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const link1 = document.createElement('link');
const link2 = document.createElement('link');
const link3 = document.createElement('link');
link1.rel = 'stylesheet';
link2.rel = 'stylesheet';
link3.rel = 'stylesheet';

// Set Css
if (process.env.NODE_ENV === 'production') {
    const devUrl = ref<string>('scraping-apidev.tobecon.io');
    const prdUrl = ref<string>('scraping-api.tobecon.io');

    link1.href = `https://${ window.location.host.includes('sandbox') ? devUrl.value : prdUrl.value }/Hanwha/css/common.css`;
    link2.href = `https://${ window.location.host.includes('sandbox') ? devUrl.value : prdUrl.value }/Hanwha/css/reset.css`;
    link3.href = `https://${ window.location.host.includes('sandbox') ? devUrl.value : prdUrl.value }/Hanwha/css/font.css`;

} else {
    link1.href = `http://localhost:${window.location.port}/assets/css/common.css`;
    link2.href = `http://localhost:${window.location.port}/assets/css/reset.css`;
    link3.href = `http://localhost:${window.location.port}/assets/css/font.css`;
}

document.head.appendChild(link1);
document.head.appendChild(link2);
document.head.appendChild(link3);

// Use Pinia
const pinia = createPinia();

createApp(App)
    .use(router)
    .use(pinia)
    .component('VueDatePicker', VueDatePicker)
.mount("#app");