import { get, post } from '@/api'
import { ref } from 'vue';

const isTest = ref<boolean>(process.env.NODE_ENV === "production");
const devUrl = ref<string>('https://scraping-apidev.tobecon.io/');
const prdUrl = ref<string>('https://scraping-api.tobecon.io/');

export default {
    TreatRequest(paramter: RequestParameter): Promise<ScrapingResult> {
        return post<ScrapingResult>({
            headers: { 'TOBECON-AUTH' : '9052e1eca3204473acf644de50c326ab' },
            baseURL: isTest.value ? window.location.host.includes('sandbox') ? devUrl.value : prdUrl.value : '',
            url: '/hanwha/api/longterminsu/request',
            data: paramter
        })
    },

    TreatConfirm(parameter: ConfirmParameter): Promise<ScrapingResult> {
        return post<ScrapingResult>({
            headers: { 'TOBECON-AUTH' : '9052e1eca3204473acf644de50c326ab' },
            baseURL: isTest.value ? window.location.host.includes('sandbox') ? devUrl.value : prdUrl.value : '',
            url: '/hanwha/api/longterminsu/confirm',
            data: parameter
        })
    },

    TreatResult(receiptId: string): Promise<ScrapingResult> {
        return get<ScrapingResult>({
            headers: { 'TOBECON-AUTH' : '9052e1eca3204473acf644de50c326ab' },
            baseURL: isTest.value ? window.location.host.includes('sandbox') ? devUrl.value : prdUrl.value : '',
            url: `/hanwha/api/longterminsu/result?receiptId=${encodeURIComponent(receiptId)}`,
        })
    },

    CheckReceiptId(receiptId: string, type: string): Promise<ValidReceiptIdResult> {
        return get<ValidReceiptIdResult>({
            baseURL: isTest.value ? window.location.host.includes('sandbox') ? devUrl.value : prdUrl.value : '',
            url: `/hanwha/api/longterminsu/check?receiptId=${encodeURIComponent(receiptId)}&type=${type}`
        })
    },

    CheckExpire(receiptId: string): Promise<ExpireResult> {
        return get<ExpireResult>({
            baseURL: isTest.value ? window.location.host.includes('sandbox') ? devUrl.value : prdUrl.value : '',
            url: `/hanwha/api/longterminsu/check-expire?receiptId=${encodeURIComponent(receiptId)}`
        })
    },

    GetTestData(): Promise<ScrapingResult> {
        return get<ScrapingResult>({
            baseURL: isTest.value ? window.location.host.includes('sandbox') ? devUrl.value : prdUrl.value : '',
            url: '/hanwha/api/longterminsu/test-data'
        })
    },

    CheckTrans(receiptId: string): Promise<ExpireResult> {
        return get<ExpireResult>({
            baseURL: isTest.value ? window.location.host.includes('sandbox') ? devUrl.value : prdUrl.value : '',
            url: `/hanwha/api/longterminsu/check-trans?receiptId=${encodeURIComponent(receiptId)}`
        })
    },

    ExpireRequest(receiptId: string): Promise<ExpireResult> {
        return get<ExpireResult>({
            baseURL: isTest.value ? window.location.host.includes('sandbox') ? devUrl.value : prdUrl.value : '',
            url: `/hanwha/api/longterminsu/expire?receiptId=${encodeURIComponent(receiptId)}`
        })
    }
}