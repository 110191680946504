import { NavigationGuard, NavigationGuardNext } from "vue-router";
import api from '@/api/Scraping'
import { ref, reactive } from 'vue'

export { accessGuard_receiptId, accessGuard_Expire, accessGuard_transCheck }

const accessGuard_receiptId: NavigationGuard = async (to, from, next) => {
    

    const { query } = to;

    if (query.receiptId) {
        if (to.name == 'result' || to.name == 'detail')
            next();

        const existsResult = reactive<ExistsResult>(Object.create(null));
        const pass = ref<boolean>(false);

        Object.assign(existsResult, await CheckReceiptId(query.receiptId.toString(), ""));
        pass.value = existsResult.isExistsRequest && !existsResult.isExistsRequestLog;

        // // result, detail이 아니고, 스크래핑 완료된 경우 전송완료 체크
        // const isTrans = ref<boolean>(false);
        // if (existsResult.isExistsRequest && existsResult.isExistsRequestLog) {
        //     isTrans.value = await CheckTrans(query.receiptId.toString());
        // }

        // // result, detail이 아니고, 전송완료일 경우 만료가 아닌 결과 페이지로 이동
        // if (existsResult.isExistsRequestLog && !isTrans.value)
        //     next({ name: 'result', query: { receiptId: query.receiptId } })

        pass.value ? next()
        : !existsResult.isExistsRequest ? invalidAccess(400, next)
        : existsResult.isExistsRequestLog ? next({ name: 'result', query: { receiptId: query.receiptId } })
        : invalidAccess(401, next);
    } else {
        invalidAccess(401, next);
    }
}

const accessGuard_transCheck: NavigationGuard = async (to, from, next) => {
    const { query } = to;

    if (query.receiptId) {
        const isTrans = ref<boolean>(false);
        isTrans.value = await CheckTrans(query.receiptId.toString());
        
        isTrans.value ? invalidAccess(408, next) : next();
    }
    else {
        invalidAccess(401, next);
    }
}


const accessGuard_Expire: NavigationGuard = async (to, from, next) => {
    const { query } = to;

    if (query.receiptId) {
        const result = await api.CheckExpire(query.receiptId.toString()).then((res: ExpireResult) => { return res.data; })
        result ? invalidAccess(408, next) : next();
    }
    else {
        invalidAccess(401, next);
    }
}

/* Error Page */
const invalidAccess = async (code: number, next: NavigationGuardNext) => {
    next({ name: 'error', params: { code: code } })
};

/* 접수번호 체크 */
const CheckReceiptId = async (receiptId: string, type: string) => {
    const result = await api.CheckReceiptId(receiptId.toString(), type).then((res: ValidReceiptIdResult) => {
        return res.data;
    }).catch((error: Error) => {
        console.log("%cError Name : %s", "background: ed;", error.name);
        console.log("%cError Stack : %s", "background: red;", error.stack);
        console.log("%cError Message : %s", "background: red;", error.message);
    });

    return result;
};

/* 전송완료 체크 */
const CheckTrans = async (receiptId: string) => {
    const isTrans = ref<boolean>(false);

    await api.CheckTrans(receiptId).then((res: ExpireResult) => {
        isTrans.value = res.data;
    })

    return isTrans.value;
}