import { createRouter, createWebHistory, RouteRecordRaw, RouterLink } from "vue-router";
import { accessGuard_receiptId, accessGuard_Expire, accessGuard_transCheck } from './accessGuard'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/scraping/agree"
  },
  {
    path: '/scraping',
    name: 'scraping',
    component: () => import(/* webpackChunkName: "MainView" */ '../views/MainView.vue'),
    children: [
      {
        path: 'agree',
        name: 'agree',
        component: () => import(/* webpackChunkName: "Agree" */ '../components/organisms/Agree.vue'),
        beforeEnter: [ accessGuard_receiptId, accessGuard_Expire ]
      },
      {
        path: 'request',
        name: 'request',
        component: () => import(/* webpackChunkName: "Request" */ '../components/organisms/Request.vue'),
        beforeEnter: [ accessGuard_receiptId, accessGuard_Expire ]
      },
      {
        path: 'sms',
        name: 'sms',
        component: () => import(/* webpackChunkName: "Sms" */ '../components/organisms/SmsAuth.vue'),
        beforeEnter: [ accessGuard_receiptId, accessGuard_Expire ]
      },
      {
        path: 'result',
        name: 'result',
        component: () => import(/* webpackChunkName: "Result" */ '../components/organisms/Result.vue'),
        beforeEnter: [ accessGuard_receiptId, accessGuard_Expire ]
      },
      {
        path: 'detail',
        name: 'detail',
        component: () => import(/* webpackChunkName: "Detail" */ '../components/organisms/Detail.vue'),
        beforeEnter: [ accessGuard_receiptId, accessGuard_Expire ]
      },
      {
        path: '/error/:code',
        name: 'error',
        component: () => import(/* webpackChunkName: "Error" */ '../components/organisms/Error.vue'),
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;